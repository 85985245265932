<template>
  <div class="baseCard thing">
    <div
      style="margin:20px 0px"
      v-html="thing.info"
    ></div>
    <div class="form">
      <div class="formLeft">
        <el-form
          :inline="true"
          ref="ruleForm"
          label-width="163px"
          label-position="left"
          :model="formThing"
          :rules="rules"
          class="donateForm"
        >
          <div>
            <el-form-item
              class="formItemAll"
              label="捐赠者名称(单位名称)"
              prop="donatorName"
            >
              <el-input
                placeholder="请输入"
                v-model="formThing.donatorName"
              > </el-input>
            </el-form-item>
            <el-form-item
              class="formItemAll"
              label="联系人"
              prop="contackUserName"
            >
              <el-input
                placeholder="请输入"
                v-model="formThing.contackUserName"
              > </el-input>
            </el-form-item>
            <el-form-item
              class="formItemAll"
              label="联系人电话"
              prop="contackPhone"
            >
              <el-input
                placeholder="请输入"
                v-model="formThing.contackPhone"
              > </el-input>
            </el-form-item>
          </div>
          <div>
            <el-upload
              class="upload-demo"
              drag
              ref="upload"
              action="/"
              :limit="10"
              name="files"
              multiple
              :before-upload="getFiles"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">请上传PDF文件</div>
            </el-upload>
            <div class="fileList">
              <p
                v-for="item in fileArr"
                :key="item.uid"
              ><span>{{item.name}}</span><i
                  @click="removeFile(item)"
                  class="el-icon-circle-close"
                ></i></p>
            </div>
          </div>
        </el-form>
        <el-button
          class="submitBtn"
          @click="onSubmit"
        >立即捐赠</el-button>
      </div>
      <div class="formRight"></div>
    </div>
  </div>
</template>

<script>
import apiUrls from "@api";
import config from "@/config";
import $ from "jquery";
export default {
  data() {
    return {
      thing: "",
      formThing: {
        donatorName: "",
        contackUserName: "",
        contackPhone: "",
      },
      fileArr: [],
      rules: {
        donatorName: [
          { required: true, message: "请填写捐赠者名称", trigger: "blur" },
        ],
        contackUserName: [
          { required: true, message: "请填写联系人", trigger: "blur" },
        ],
        contackPhone: [
          { required: true, message: "请填写联系人电话", trigger: "blur" },
        ],
      },
    };
  },

  components: {},

  mounted() {
    this.getDonatething();
  },

  methods: {
    getDonatething() {
      apiUrls.trainNum({ node: "wyjw" }).then((res) => {
        this.thing = res.results.data;
      });
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          var formdata = new FormData();
          formdata.append("donatorName", this.formThing.donatorName);
          formdata.append("contackUserName", this.formThing.contackUserName);
          formdata.append("contackPhone", this.formThing.contackPhone);
          this.fileArr.forEach((el) => {
            formdata.append("files", el);
          });
          $.ajax({
            url: `${config.baseUrl2}/donate/portal/saveOnlineGoodPc.json`,
            dataType: "json",
            type: "POST",
            async: false,
            data: formdata,
            traditional: true,
            processData: false, // 使数据不做处理
            contentType: false, // 不要设置Content-Type请求头
            success:  (data) =>{
              if (data.rCode == 0) {
                this.$message({
                  message: "提交成功",
                  type: "success",
                });
                this.formThing = {
                  donatorName: "",
                  contackUserName: "",
                  contackPhone: "",
                };
                this.fileArr = [];
              }
            },
            error: function (response) {},
          });
        } else {
          return false;
        }
      });
    },
    getFiles(file) {
      this.fileArr.push(file);
    },
    removeFile(file) {
      this.fileArr.map((el, index) => {
        el.uid == file.uid && this.fileArr.splice(index, 1);
        return el;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.thing {
  padding: 20px;
  a {
    display: inline-block;
    color: red;
    margin: 5px 0px;
  }
  .form {
    display: flex;
    justify-content: space-between;
    .formRightInput {
      display: flex;
      align-items: center;
      margin-top: 30px;
      .formRightLabel {
        width: 50px;
      }
    }
    .formLeft {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .donateForm {
        display: flex;
        justify-content: space-between;
        .formItemAll {
          width: 100%;
          /deep/ .el-form-item__content {
            width: 70%;
          }
        }
      }
      .submitBtn {
        width: 200px;
        margin: 20px auto;
      }
    }
  }
  .fileList {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    p {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #e3e3e3;
      padding: 10px;
    }
  }
}
</style>