<template>
  <div class="baseCard">
    <TitleMore
      :title="['捐款查询']"
      :isMore="false"
    ></TitleMore>
    <div class="searchCard">
      <el-form
        style="width:40%"
        label-width="100px"
        label-position="right"
        :model="formInline"
        class="donateForm"
      >
        <el-form-item
          label="捐赠人名称"
          style="width:100%"
        >
          <el-input
            v-model="donateName1"
            style="width:100%"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="捐款金额"
          style="width:100%;"
        >
          <el-input
            style="width:45%"
            type="number"
            v-model="minAmount1"
            placeholder="请输入"
          ></el-input>
          <span class="zhi">至</span>
          <el-input
            style="width:45%"
            type="number"
            v-model="maxAmount1"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item
          style="width:100%;"
          label="捐赠日期"
        >
          <el-date-picker
            style="width:45%"
            v-model="startDate1"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
          <span class="zhi">至</span>
          <el-date-picker
            style="width:45%"
            v-model="endDate1"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            class="submitBtn"
            @click="onSearch(1)"
          >立即查询</el-button>
        </el-form-item>
      </el-form>
      <div class="donateHint">
        <p class="hintTitle">查询说明</p>
        <p style="font-size: 16px;
color: #666666;
line-height: 28px;">如果您在捐赠查询中未查到自己的捐赠信息，可能存在以下情况：
          1、输入的查询条件是否准确；
          2、收款单位是否为中国红十字会总会。如果收款单位为中国红十字基金会、
          地方红十字会，请您联系相应的收款单位查询；
          3、如果仍未查到捐款信息，您可通过电话028-84350528（工作日）进行查询；
          4、本平台可查询2010年1月11日以来向中国红十字会总会捐赠信息；
          感谢您的爱心捐赠！</p>
      </div>
    </div>
    <TitleMore
      :title="['捐赠证书查询']"
      :isMore="false"
    ></TitleMore>
    <div class="searchCard">
      <el-form
        style="width:40%"
        label-width="100px"
        label-position="right"
        :model="formInline"
        class="donateForm"
      >
        <el-form-item
          label="捐赠人名称"
          style="width:100%"
        >
          <el-input
            v-model="donateName2"
            style="width:100%"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="捐款金额"
          style="width:100%;"
        >
          <el-input
            style="width:45%"
            type="number"
            v-model="minAmount2"
            placeholder="请输入"
          ></el-input>
          <span class="zhi">至</span>
          <el-input
            style="width:45%"
            type="number"
            v-model="maxAmount2"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item
          style="width:100%;"
          label="捐赠日期"
        >
          <el-date-picker
            style="width:45%"
            v-model="startDate2"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
          <span class="zhi">至</span>
          <el-date-picker
            style="width:45%"
            v-model="endDate2"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            class="submitBtn"
            @click="onSearch(4)"
          >立即查询</el-button>
        </el-form-item>
      </el-form>
      <div class="donateHint">
        <p class="hintTitle">查询说明</p>
        <p style="font-size: 16px;
color: #666666;
line-height: 28px;">如果您在捐赠查询中未查到自己的捐赠信息，可能存在以下情况：
          1、输入的查询条件是否准确；
          2、收款单位是否为中国红十字会总会。如果收款单位为中国红十字基金会、
          地方红十字会，请您联系相应的收款单位查询；
          3、如果仍未查到捐款信息，您可通过电话028-84350528（工作日）进行查询；
          4、本平台可查询2010年1月11日以来向中国红十字会总会捐赠信息；
          感谢您的爱心捐赠！</p>
      </div>
    </div>
    <TitleMore
      :title="['捐物查询']"
      :isMore="false"
    ></TitleMore>
    <div class="searchCard">
      <el-form
        style="width:40%"
        label-width="100px"
        label-position="right"
        :model="formInline"
        class="donateForm"
      >
        <el-form-item
          label="捐赠人名称"
          style="width:100%"
        >
          <el-input
            v-model="donateName3"
            style="width:100%"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            class="submitBtn"
            @click="onSearch(2)"
          >立即查询</el-button>
        </el-form-item>
      </el-form>
      <div class="donateHint">
        <p class="hintTitle">查询说明</p>
        <p style="font-size: 16px;
color: #666666;
line-height: 28px;">如果您在捐赠查询中未查到自己的捐赠信息，可能存在以下情况：
          1、输入的查询条件是否准确；
          2、收款单位是否为中国红十字会总会。如果收款单位为中国红十字基金会、
          地方红十字会，请您联系相应的收款单位查询；
          3、如果仍未查到捐款信息，您可通过电话028-84350528（工作日）进行查询；
          4、本平台可查询2010年1月11日以来向中国红十字会总会捐赠信息；
          感谢您的爱心捐赠！</p>
      </div>
    </div>
    <TitleMore
      :title="['其他捐赠 查询']"
      :isMore="false"
    ></TitleMore>
    <div class="searchCard">
      <el-form
        style="width:40%"
        label-width="100px"
        label-position="right"
        :model="formInline"
        class="donateForm"
      >
        <el-form-item
          label="捐赠人名称"
          style="width:100%"
        >
          <el-input
            v-model="donateName4"
            style="width:100%"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            class="submitBtn"
            @click="onSearch(3)"
          >立即查询</el-button>
        </el-form-item>
      </el-form>
      <div class="donateHint">
        <p class="hintTitle">查询说明</p>
        <p style="font-size: 16px;
color: #666666;
line-height: 28px;">如果您在捐赠查询中未查到自己的捐赠信息，可能存在以下情况：
          1、输入的查询条件是否准确；
          2、收款单位是否为中国红十字会总会。如果收款单位为中国红十字基金会、
          地方红十字会，请您联系相应的收款单位查询；
          3、如果仍未查到捐款信息，您可通过电话028-84350528（工作日）进行查询；
          4、本平台可查询2010年1月11日以来向中国红十字会总会捐赠信息；
          感谢您的爱心捐赠！</p>
      </div>
    </div>
  </div>
</template>

<script>
import TitleMore from "@components/TitleMore";
export default {
  data() {
    return {
      donateName1: "",
      donateName2: "",
      donateName3: "",
      donateName4: "",
      minAmount1: null,
      minAmount2: null,
      maxAmount1: null,
      maxAmount2: null,
      startDate1: "",
      startDate2: "",
      endDate1: "",
      endDate2: "",
    };
  },

  components: {
    TitleMore,
  },

  mounted() {},

  methods: {
    onSearch(type) {
      type != 4 &&
        this.$router.push({
          path: "/donateInfo",
          query: {
            type,
            donateName:
              this.donateName1 || this.donateName3 || this.donateName4,
            minAmount: this.minAmount1,
            maxAmount: this.maxAmount1,
            startDate: this.startDate1,
            endDate: this.endDate1,
          },
        });
      type == 4 &&
        this.$router.push({
          path: "/certificate",
          query: {
            donateName: this.donateName2,
            minAmount: this.minAmount2,
            maxAmount: this.maxAmount2,
            startDate: this.startDate2,
            endDate: this.endDate2,
          },
        });
    },
  },
};
</script>
<style lang="less" scoped>
.searchCard {
  display: flex;
  justify-content: space-between;
  .donateHint {
    width: 50%;
    border: 1px solid #ff7b7f;
    border-radius: 10px;
    padding: 20px;
    .hintTitle {
      color: #333333;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
  .zhi {
    display: inline-block;
    margin: 0px 10px;
  }
}
</style>