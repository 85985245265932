<template>
  <div class="baseCard">
    <p class="title">邮局电汇</p>
     <div
      style="margin-top:20px"
      v-html="infodata"
    ></div>
		<p class="donateHint">您10元及以下的捐款将进入网上小额募捐箱，汇聚统一用于符合红十字宗旨的人道公益事业。</p>
  </div>
</template>

<script>
import apiUrls from "@api";
export default {
  data() {
    return {
      infodata: "",
    };
  },

  components: {},

  mounted() {
    this.getData();
  },

  methods: {
    getData() {
      apiUrls.trainNum({ node: "yjdh" }).then((res) => {
        this.infodata = res.results.data.info;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.baseCard {
  width: 950px;
  margin: 0px;
	position: relative;
  .title {
    font-size: 18px;
    font-weight: bold;
  }
}
.donateHint{
	font-size: 14px;
	color: #888888;
	padding: 10px 0px 0px 20px;
	position: absolute;
	bottom: 20px;
}
</style>